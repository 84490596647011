<template>
  <b-navbar toggleable="lg" type="dark" sticky>
    <b-navbar-brand href="#">
      <img src="@/assets/images/import/cpts/logo-header.png" style="width:11rem" alt="">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <!-- Accueil -->
        <b-nav-item to="/">ACCUEIL</b-nav-item>
        <div class="border-right"></div>
        <!-- Présentation -->
        <b-nav-item-dropdown right>
          <template #button-content>
            PRESENTATION <i class="mdi mdi-menu-down"></i>
          </template>
          <b-dropdown-item to="/presentation">Presentation</b-dropdown-item>
          <b-dropdown-item to="/presentation-cpts">CPTS</b-dropdown-item>
        </b-nav-item-dropdown>
        <div class="border-right"></div>
        <!-- Nos actions -->
        <b-nav-item to="/actions">NOS ACTIONS</b-nav-item>
        <div class="border-right"></div>
        <!-- Recherche médecin traitant -->
        <b-nav-item to="/rechercheMedecin">RECHERCHE MEDECIN TRAITANT</b-nav-item>
        <div class="border-right"></div>
        <!-- Nos actions -->
        <b-nav-item-dropdown right>
          <template #button-content>
            INFOS PRATIQUES <i class="mdi mdi-menu-down"></i>
          </template>
          <b-dropdown-item to="/urgence">N° D'URGENCE</b-dropdown-item>
          <b-dropdown-item to="/geolocalisation">TERRITOIRE  </b-dropdown-item>
        </b-nav-item-dropdown>
        <div class="border-right"></div>
        <!-- Actualités -->
        <b-nav-item to="actualites">ACTUALITES</b-nav-item>
        <div class="border-right"></div>
        <!-- Contact -->
        <b-nav-item to="/contact">CONTACT</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em><i class="fa fa-user"></i></em>
          </template>
          <b-dropdown-item to="profil">Profil</b-dropdown-item>
          <b-dropdown-item href="#" @click="login()">Se connecter</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "app-header",
  data() {
    return {
      isconnected: true,
      //perso
      showNavbar: true,
      lastScrollPosition: 0,
      visible: true
    };
  },
  methods: {
    login(){
      this.$router.push({
        name:"login"
      })
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({
        name:"login"
      })
    },
    toggleMobileSidebar: () => {
      document
        .querySelector(".bottom-navbar")
        .classList.toggle("header-toggled");
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    handleSCroll() {
      let header = document.querySelector("body");
      if (window.scrollY > 70) {
        header.classList.add("fixed-on-scroll");
      } else {
        header.classList.remove("fixed-on-scroll");
      }
    },
    onScroll () {
    // Get the current scroll position
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
     // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    if (currentScrollPosition < 0) {
      return
    } 
    // Stop executing this function if the difference between
  // current scroll position and last scroll position is less than some offset
    if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
    return
  }
    // Here we determine whether we need to show or hide the navbar
    this.showNavbar = currentScrollPosition < this.lastScrollPosition
    // Set the current scroll position as the last scroll position
    this.lastScrollPosition = currentScrollPosition
  },
    visibility(e) {
      if(e.deltaY>0){
        this.visible=false
      }
      else{
        this.visible=true
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleSCroll);
    window.addEventListener("scroll", this.onScroll);
  },
  mounted() {
    window.addEventListener("wheel", this.visibility)
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleSCroll);
    window.removeEventListener("scroll", this.onScroll);
  },
  watch: {
    $route() {
      document
        .querySelector(".bottom-navbar")
        .classList.remove("header-toggled");
    }
  },
};
</script>

<style scoped>
.navbar {
  background-color: #8C1078 !important;
}
ul li a, ::v-deep .nav-link{
  color: rgb(239, 239, 239) !important;
}
.border-right {
  border-right: 3px solid rgba(255, 255, 255, 0.201) !important
}

::v-deep .dropdown-item.active, ::v-deep .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #8C1078;
}
</style>