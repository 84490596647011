<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix d-flex justify-content-between">
        <!-- rangée 1 -->
        <div class="">
          <div class="d-flex align-items-center justify-content-around">
            <img src="@/assets/images/import/cpts/logo-mini.png" alt="">
            LOCALISATION
          </div>
          <div class="d-flex justify-content-around">
            <img class="social-img" src="@/assets/images/import/cpts/facebook.png" alt="">
            <img class="social-img" src="@/assets/images/import/cpts/linkedin.png" alt="">
            <img class="social-img" id="twitter-img" src="@/assets/images/import/cpts/twitter.png" alt="">
          </div>
        </div>
        <span class="border-right"></span>
        <!-- rangée 2 -->
        <div class="contact d-flex flex-column align-items-around justify-content-around px-4">
          <div class="d-flex justify-content-around align-items-center">
            <i class="fa fa-map-marker"></i>
            <p>Adresse <br> Code postale - Ville</p>    
          </div>
          <div class="d-flex justify-content-around align-items-center">
            <i class="fa fa-phone"></i> 
            <p>Numéro téléphone</p>
          </div>
          <div class="d-flex justify-content-around align-items-center">
            <i class="mdi mdi-email"></i>
            <p> mail.cpts@gmail.com</p>
          </div>
        </div>
        <span class="border-right"></span>
        <!-- rangée 3 -->
        <div style="min-width:18rem">
          <ul>
            <li>Présentation</li>
            <li>CPTS</li>
            <li>Nos actions</li>
            <li>Recherche médecin traitant</li>
            <li>N° d'urgence</li>
            <li>Territoire</li>
            <li>Actualités</li>
            <li>Contact</li>
            <li>Mon compte</li>
          </ul>
          <div class="d-flex justify-content-between">
            <label>Conditions générales</label>
            <label>Mentions légales</label>
          </div>
        </div>
        <!-- rangée 4 -->
        <span class="border-right"></span>
        <div class="d-flex flex-column justify-content-around align-items-center" style="max-width:15rem">
          <h3> Ensemble, dynamisons la santé sur notre Territoire</h3>
          <img class="mini-logo" src="@/assets/images/import/cpts/logo-mini.png" alt="">
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>
<style scoped>
footer {
  background: #8C1078;
}
footer p, footer ul li, footer label, footer h3 {
  color: #fff;
}
.social-img {
  width: 4rem;
}
::v-deep .contact .fa, .contact .mdi{
  font-size:2rem
}
.mini-logo {
  width: 5rem;
}
.border-right {
  border-right: 2px rgb(255, 254, 254) solid !important;
}
.app-footer {
  z-index: 20;
}
</style>