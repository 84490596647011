import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout/landing-page'

Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior (to) {
    if (to.hash) {
      return { selector : to.hash}
    }else {
      return {y:0}
    }
  }, 
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '',
      component:layout,
      children: [
        {
          path:'',
          name: 'home',          
          component: () => import ('@/pages/cpts/accueil.vue')
        }
      ]
    },
    {
      path: '/presentation',
      component:layout,
      children: [
        {
          path:'',
          name: 'presentation',          
          component: () => import ('@/pages/cpts/presentation.vue')
        }
      ]
    },
    {
      path: '/presentation-cpts',
      component:layout,
      children: [
        {
          path:'',
          name: 'presentation-cpts',          
          component: () => import ('@/pages/cpts/presentationCpts.vue')
        }
      ]
    },
    {
      path: '/actions',
      component:layout,
      children: [
        {
          path:'',
          name: 'actions',          
          component: () => import ('@/pages/cpts/actions.vue')
        }
      ]
    },
    {
      path: '/rechercheMedecin',
      component:layout,
      children: [
        {
          path:'',
          name: 'rechercheMedecin',          
          component: () => import ('@/pages/cpts/rechercheMedecin.vue')
        }
      ]
    },
    {
      path: '/urgence',
      component:layout,
      children: [
        {
          path:'',
          name: 'urgence',          
          component: () => import ('@/pages/cpts/urgence.vue')
        }
      ]
    },
    {
      path: '/geolocalisation',
      component:layout,
      children: [
        {
          path:'',
          name: 'geolocalisation',          
          component: () => import ('@/pages/cpts/geolocalisation.vue')
        }
      ]
    },
    {
      path: '/contact',
      component:layout,
      children: [
        {
          path:'',
          name: 'contact',          
          component: () => import ('@/pages/cpts/contact.vue')
        }
      ]
    },
    {
      path: '/profil',
      component:layout,
      children: [
        {
          path:'',
          name: 'profil',          
          component: () => import ('@/pages/cpts/profil.vue')
        }
      ]
    },
    {
      path: '/actualites',
      component:layout,
      children: [
        {
          path:'',
          name: 'posts',          
          component: () => import ('@/pages/ailem/posts.vue')
        },
        {
          path:':ulid',
          name: 'postDetails',          
          component: () => import ('@/pages/dashboard/sites/posts/postDetails.vue')
        }
      ]
    },
    {
      path: '/login',
      component:layout,
      children: [
        {
          path:'',
          name: 'login',          
          component: () => import ('@/pages/ailem/login.vue')
        }
      ]
    },
    

  ]
})
